<template>
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <a class="navbar-brand" href="#"><img alt="Popular Coding" src="../assets/logo.svg"></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <a class="nav-link" href="#tech">Technologies</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#follow">Follow Us</a>
        </li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="#">Jobs</a>
        </li> -->
        <li class="nav-item">
          <a class="nav-link" target="_blank" href="https://medium.com/popular-coding">Blog</a>
        </li>
    </ul>
    </div>
  </div>
</nav>
</template>

<script>
export default {
  name: 'NavBar'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-light {
    background-color: #ffffff !important;
}
nav{
  height: 88px;
}
li>a{
  font-weight: 700;
  font-size: 16px;
  color: #000000;
}
.navbar-brand > img{
    height: 48px;
}
</style>
