<template>
<div id="summary" class="container">
  <img id="chain-icon" alt="Chain" src="../assets/chain_icon.svg">
  <h1>Networks and Services<br>for Professionals</h1>

  <p>We build Networks and Services that bring value to professionals. Web3 redefines the scope of professional interactions, both between businesses and individuals, as well as for person to person interactions for crowd based grant and venture funding. In this new landscape, Popular Coding is committed to building solutions that enable these new interactions.</p>
  
<!--   <div class="row align-items-center justify-content-evenly">
    <div class="col-12 col-md-4">
    <h2>Networks</h2>
    <font-awesome-icon icon="fa-solid fa-bolt" />
    <p>We build Networks and Services that bring value to professionals. Web3 redefines the scope of professional interactions, both between businesses and individuals, as well as for person to person interactions for crowd based grant and venture funding.</p>
    </div>

    <div class="col-12 col-md-4">
    <h2>Networks</h2>
    <font-awesome-icon icon="fa-solid fa-cat" />
    <p>We build Networks and Services that bring value to professionals. Web3 redefines the scope of professional interactions, both between businesses and individuals, as well as for person to person interactions for crowd based grant and venture funding.</p>
    </div>

    <div class="col-12 col-md-4">
    <h2>Networks</h2>
    <font-awesome-icon icon="fa-solid fa-code" />
    <p>We build Networks and Services that bring value to professionals. Web3 redefines the scope of professional interactions, both between businesses and individuals, as well as for person to person interactions for crowd based grant and venture funding.</p>
    </div>
  </div> -->
</div>
</template>

<script>
export default {
  name: 'SummarySection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#summary{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
h1{
    font-weight: bold;
}
#chain-icon{
    height: 80px;
    width: 100px;
}
svg{
    color: #B30F14;
    height: 80px;
}
p{
  width: 720px;
  margin: auto;
  text-align: center;
}
</style>
