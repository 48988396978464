<template>
  <NavBar/>
  <Hero/>
  <Summary/>
  <Engineering/>
  <Ventur/>
  <!-- <About/> -->
  <Follow/>
  <Footer/>
</template>

<script>
import NavBar from './components/NavBar.vue'
import Hero from './components/Hero.vue'
import Summary from './components/Summary.vue'
import Engineering from './components/Engineering.vue'
import Ventur from './components/Ventur.vue'
// import About from './components/About.vue'
import Follow from './components/Follow.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'App',
  components: {
    NavBar,
    Hero,
    Summary,
    Engineering,
    Ventur,
    // About,
    Follow,
    Footer
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
