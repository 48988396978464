import "bootstrap/dist/css/bootstrap.min.css"
import { createApp } from 'vue'
import App from './App.vue'
const Vue3Mq = require('vue3-mq');
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
import { faGithub } from '@fortawesome/free-brands-svg-icons'
import { faBolt } from '@fortawesome/free-solid-svg-icons'
import { faCat } from '@fortawesome/free-solid-svg-icons'
import { faCode } from '@fortawesome/free-solid-svg-icons'
import { faCodePullRequest } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import { faVialCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { faServer } from "@fortawesome/free-solid-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faMedium } from "@fortawesome/free-brands-svg-icons";
import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faGithub, faBolt, faCat, faCode, faCodePullRequest, faBuilding, faVialCircleCheck, faArrowsUpDownLeftRight, faServer, faTwitter, faLinkedin, faMedium, faShieldHalved)
const app = createApp(App)
app.component('MqResponsive', Vue3Mq.MqResponsive).component('font-awesome-icon', FontAwesomeIcon).mount('#app')

Vue3Mq.updateBreakpoints({
    preset: "bootstrap5"
  })

import "bootstrap/dist/js/bootstrap.min.js"