<template>
<MqResponsive target="lg+">
<div id="follow"><div class="container">
<div class="row align-items-center justify-content-between">
<div class="col-12 col-md-5">
<h1 class="text-start">Follow Our Progress</h1>
<p class="text-start ">Open Engineering: Follow our work and step into the worlds of Decentralized and Distributed workloads.</p>

<!-- <div class="d-grid float-start gap-2 d-md-block justify-content-between">
<a target="_blank" href="https://github.com/Popular-Coding" type="button" id="github-button" class="btn btn-danger">GitHub</a>
<a target="_blank" href="https://medium.com/popular-coding" type="button" class="btn btn btn-outline-dark">Engineering Blog</a>
</div> -->
</div>
<div class="col-12 col-md-6">
<div class="row float-start justify-content-evenly brands text-start">
<div>
<a target="_blank" href="https://github.com/Popular-Coding" class="brands"><font-awesome-icon icon="fa-brands fa-github inline" />  <span>GitHub</span></a>
</div>
<div>
<a target="_blank" href="https://twitter.com/PopularCoding" class="brands"><font-awesome-icon icon="fa-brands fa-twitter inline" />  <span>Twitter</span></a>
</div>
<div>
<a target="_blank" href="https://medium.com/popular-coding" class="brands"><font-awesome-icon icon="fa-brands fa-medium inline" /> <span>Medium</span></a>
</div>
</div>
</div>
</div>
</div>
</div>
</MqResponsive>
<MqResponsive target="md-">
<div id="follow2"><div class="container">
<div class="row align-items-center justify-content-between">
<div class="col-12 col-md-5">
<h1 class="text-start">Follow Our Progress</h1>
<p class="text-start">Open Engineering: Follow our work and step into the worlds of Decentralized and Distributed workloads.</p>

<!-- <div class="d-grid float-start gap-2 d-md-block justify-content-between">
<a target="_blank" href="https://github.com/Popular-Coding" type="button" id="github-button" class="btn btn-danger">GitHub</a>
<a target="_blank" href="https://medium.com/popular-coding" type="button" class="btn btn btn-outline-dark">Engineering Blog</a>
</div> -->
</div>
<div class="col-12 col-md-6">
<div class="row float-start justify-content-evenly">
<div class="col-12 col-md-6">
</div>
</div>
</div>
</div>
</div>
</div>
</MqResponsive>
</template>

<script>
export default {
  name: 'HeroSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#follow{
    background: linear-gradient(120deg, #111114, #111114 50%, #B30F14 50%, #B30F14), url('@/assets/hero.webp') ;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    /* height: auto; */
    padding-top: 5rem;
    padding-bottom: 5rem;
    z-index: -1;
    color: #fff;
}
#follow2{

    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    /* height: auto; */
    padding-top: 5rem;
    padding-bottom: 5rem;
    z-index: -1;
    background-color: #111114;
    color: #fff;
}
#hero-header{
    font-size: 3rem;
    font-weight: bold;
    padding-top: 10%;
    color: #fff;
    font: normal normal bold 64px/74px Arial;   
}
#hero-tagline{
    font: normal normal bold 24px/28px Arial;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
#hero-nav{
    padding-bottom: 5%;
    font: normal normal bold 16px/18px Arial;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    margin-left: -16px;
}
.nav-link{
    color: #FFFFFF;
}
hr{
    border: 1.5px solid #ffffff;
    color: #ffffff;
    width: 20%;
}
.vertical-line{
    padding-top: 6px;
}
h1{
    padding-top: 2%;
    font-weight: bold;
    font: normal normal bold 56px/65px Arial;
}
#chain-icon{
    height: 80px;
    width: 100px;
}
hr{
    border: 2.5px solid #707070;
    opacity: 1;
    margin-top: 7px;
    margin-bottom: 7px;
}
button{
    border-radius: 4px;
    font: normal normal normal 20px/23px Arial;
    padding-inline: 25px;
}
a.btn{
    border-radius: 4px;
    font: normal normal normal 20px/23px Arial;
    padding-inline: 25px;
}
#github-button{
    margin-right: 1.5rem;
}
.brands{
    padding-left: 4rem;
    color: white;
    font-size: 3rem;
    font-weight: bold;
    vertical-align: middle;
}
.brands>span{
    font-size: 2.5rem;
}
a.brands{
    text-decoration: none;
    color: white;
}
</style>
