<template>
<div id="tech" class="container">
<h1>Our Networks and Projects</h1>
<div id="ventur" class="row round-up">
  <div class="col-12 col-md-4 h-10 d-flex align-items-center justify-content-center">
    <img id="ventur-logo" alt="Ventur" src="../assets/ventur/ventur-long-white.svg">
  </div>
  <div class="col-12 col-md-8 h-10 p-10 text-start">
    <p>Ventur is a blockchain focused on enabling businesses and professionals.  Built using the Substrate framework, Ventur aims to integrate with the Polkadot ecosystem and is working to enable dApps for work engagement, technical certification and professional accredidation, memberships and subscription services, and professional networking.</p>
    <p>Start your next venture with Ventur.</p>
  </div>
</div>
<div class="row">
  <div class="col-4 h-10">
    <img class="img-2" alt="Ventur" src="../assets/ventur/ventur-envlp-500.webp">
  </div>
  <div class="col-4 h-10">
    <img class="img-4" alt="Ventur" src="../assets/ventur/ventur-black-alt.webp">
  </div>
  <div class="col-4 h-10">
    <img class="img-3" alt="Substrate" src="../assets/substrate.webp">
  </div>
</div>
<div id="ventur" class="row round-up">
  <div class="col-12 col-md-4 h-10 d-flex flex-column align-items-center justify-content-center">
    <img class="ventur-verified" alt="Ventur" src="../assets/ventur/ventur-verified.png">
  </div>
  <div class="col-12 col-md-8 h-10 p-10 text-start">
    <p>Ventur Verified is a decentralized application for credentialing for the dotsama ecosystem.</p>
    <p>Built on top of Ventur’s Non-Transferable NFTs (NT-NFTs), Ventur Verified allows for the management of credentials in the Polkadot Ecosystem, enabling automated credential verification for job applications, rfp proposals, content access, and more.</p>
  </div>
</div>
<div class="row">
  <div class="col-4 h-10">
    <img class="img-3" alt="GitHub" src="../assets/ventur/verified/gh.webp">
  </div>
  <div class="col-4 h-10">
    <img class="img-3" alt="Profile UI" src="../assets/ventur/verified/profile.webp">
  </div>
  <div class="col-4 h-10">
    <img class="img-3" alt="Ventur Verified Flow" src="../assets/ventur/verified/ventur-verified-flow.webp">
  </div>
</div>
</div>
</template>

<script>
export default {
  name: 'VenturSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#technology{
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}
.round-up{
  border-radius: 10px;
  background-color: #1d1d20;
  color: #fff;
  padding: 2%;
}
#ventur-logo {
  width:75%;

}
.ventur-verified{
  width: 200px;
}
h1{
    padding-top: 2%;
    font-weight: bold;
}
#chain-icon{
    height: 80px;
    width: 100px;
}
.img-2{
  width:100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
}
.img-3{
  border-radius: 10px;
  width:100%;
  height: 300px;
  object-fit: contain;
  background-color: #242A35;
}
.img-4{
  border-radius: 10px;
  width:100%;
  height: 300px;
  object-fit: contain;
  background-color: #000000;
}
.row{
  margin-top: 12px;
  margin-bottom: 12px;
}
</style>
