<template>
<div id="engineering">
<hr class="top-hr">
<div class="container">
<div class="row align-items-center justify-content-between">
    <div class="col-12 col-md-5">
        <h1 class="text-start">Trustless Engineering</h1>
        <p class="text-start">Trustless Engineering is the principle of engineering decentralized solutions where the system functionally ensures the validity of transactions, participants do not need to trust each other or any central authority.</p>
    </div>
    <div class="col-12 col-md-6">
        <div class="row text-start justify-content-evenly">
            <div class="col-12 col-md-6 item">
                <div class="row text-start justify-content-evenly">
                    <div class="col-2"><font-awesome-icon icon="fa-solid fa-server" /></div>
                    <div class="col-10">
                        <h2 class="d-inline">Node Driven</h2>
                        <p>We build open distributed systems, where anyone is free to run nodes and contribute to our networks.</p>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 item">
                <div class="row text-start justify-content-evenly">
                <div class="col-2"><font-awesome-icon icon="fa-solid fa-shield-halved" /></div>
                    <div class="col-10">
                        <h2 class="d-inline">Secure</h2>
                        <p>We build on top of secure and audited technologies like Substrate to bring tested security to our solutions.</p>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 item">
                <div class="row text-start justify-content-evenly">
                <div class="col-2"><font-awesome-icon icon="fa-solid fa-vial-circle-check" /></div>
                    <div class="col-10">
                        <h2 class="d-inline">Tested</h2>
                        <p>With testnets and canary networks, we put our solutions through both internal and real-world testing.</p>
                    </div>
                </div>
            </div>

            <div class="col-12 col-md-6 item">
                <div class="row text-start justify-content-evenly">
                <div class="col-2"><font-awesome-icon icon="fa-solid fa-code-pull-request" /></div>
                    <div class="col-10">
                        <h2 class="d-inline">Agile</h2>
                        <p>Technology changes at a rapid pace, our teams are ready to keep up.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div><!-- row -->
</div><!-- container -->
<hr class="bottom-hr">
</div><!-- engineering -->
</template>

<script>
export default {
  name: 'EngineeringSection'
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.top-hr{
    margin-bottom: 3.5rem;
}
.bottom-hr{
    margin-top: 3.5rem;
}
#engineering{
    background-color: #1d1d20;
    color: #fff;
}
h1{
    padding-top: 2%;
    font-weight: bold;
    font: normal normal bold 32px Arial;
}
p{
    font-size: 16px;
}
#chain-icon{
    height: 80px;
    width: 100px;
}
hr{
    border: 2.5px solid #ffffff;
    opacity: 1;
    margin-top: 7px;
    margin-bottom: 7px;
}
svg{
    height: 32px;
    margin-top: 5px;
    margin-right: 1.5rem;
}
.item{
    padding-right: 3rem;
}
</style>
