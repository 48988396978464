<template>
<footer class="footer">
<div class="container">
<div class="row">
<div class="col-12 col-md-3">
<img id="footer-logo" alt="Popular Coding" src="../assets/white-logo.svg">
</div>
<div class="col-12 col-md-5">
<div class="row container text-start">
<div class="col-12 col-md-4">
<h5>Company</h5>
<!-- <a href="#team">About</a><br> -->
<a target="_blank" href="https://medium.com/popular-coding">Blog</a><br>
<a href="contact@popularcoding.com">Contact</a>
<!-- <p>Jobs</p> -->
</div>
<div class="col-12 col-md-4">
<h5>Technologies</h5>
<a target="_blank" href="https://github.com/Popular-Coding/ventur">Ventur</a><br>
<a target="_blank" href="https://github.com/Popular-Coding/ventur-verified">Verified</a>
<!-- <p>RFP Market</p> -->
</div>
<div class="col-12 col-md-4">
<h5>Communities</h5>
<a target="_blank" href="https://github.com/Popular-Coding">GitHub</a>
<!-- <p>Podcast</p> -->
<!-- <p>Events</p> -->
<!-- <p>Discord</p> -->
</div>
</div>
</div>
<div id="terms-div" class="col-12 col-md-4 justify-content-end text-end">
<!-- <p>Terms of Use</p>
<p>Privacy Policy</p>
<p>Cookie Settings</p> -->
<p>&#169; {{year}} Popular Coding All Rights Reserved</p>
</div>
</div>
</div>
</footer>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a{
    color:#D5D5D5;
    text-decoration:none;
}
a:hover{
    color:#D5D5D5;
    text-decoration:none;
}
#footer-logo{
    height: 54px;
    margin: 1rem;
}
footer{
    background-color: #262626;
    color: #D5D5D5;
    padding-top: 70px;
    min-height: 216px;
}
.navbar-brand > img{
    height: 68px;
}
p{
    margin: 0;
    line-height: 18px;
}
h5{
    color: #474747;
}
#terms-div{
    padding-bottom: 1rem;
    padding-top: 2rem;
    color: #7d7d7d;
}
</style>
