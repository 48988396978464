<template>
<MqResponsive target="lg+">
<div class="hero">
    <div class="container">
        <h1 id="hero-header" class="text-start">Web3<br>Realized</h1>
        <hr class="opacity-100">
        <h2 id="hero-tagline" class="text-start">Bringing Engineering<br>to Life</h2>
        <div id="hero-nav" class="text-start">
            Learn More &#8595;
        </div>
    </div>
</div>
</MqResponsive>
<MqResponsive target="md-">
<div class="hero2">
    <div class="container">
        <h1 id="hero-header" class="text-start">Web3<br>Realized</h1>
        <hr class="opacity-100">
        <h2 id="hero-tagline" class="text-start">Bringing Engineering<br>to Life</h2>
        <div id="hero-nav" class="text-start">
            Learn More &#8595;
        </div>
    </div>
</div>
</MqResponsive>
</template>

<script>
export default {
  name: 'HeroSection',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.hero{
    background: linear-gradient(120deg, #B30F14, #B30F14 50%, rgba(0,0,0,0) 50%,rgba(0,0,0,0)), url('@/assets/hero.webp') ;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    /* height: auto; */
    z-index: -1;
}
.hero2{
    background: #B30F14;
    background-size: cover;
    background-position: right;
    background-repeat: no-repeat;
    /* height: auto; */
    z-index: -1;
}
#hero-header{
    font-size: 3rem;
    font-weight: bold;
    padding-top: 10%;
    color: #fff;
    font: normal normal bold 64px/74px Arial;   
}
#hero-tagline{
    font: normal normal bold 24px/28px Arial;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
}
#hero-nav{
    padding-bottom: 5%;
    font: normal normal bold 16px/18px Arial;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
}
.nav-link{
    color: #FFFFFF;
}
hr{
    border: 1.5px solid #ffffff;
    color: #ffffff;
    width: 20%;
}
.vertical-line{
    padding-top: 6px;
}
</style>
